import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { formatDynamicLink } from '../../util/data';
import { Heading, ExternalLink, IconSocialMediaInstagram } from '../../components';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const ARCHITECTURE_KEY = 'architecture';

const findListingField = (fields, key) => fields.find(f => f.key === key)?.enumOptions;
export const findExtendedDataValue = (options, valueKey) =>
  options.find(o => o.option === valueKey)?.label;
export const filterExtendedDataValues = (options, valueKeys) =>
  options.filter(o => valueKeys?.includes(o.option)).map(o => o.label);

const renderField = (titleMsgId, value, link) => {
  return value ? (
    <li className={css.detailsRow}>
      <span className={css.detailLabel}>
        <FormattedMessage id={titleMsgId} />
      </span>
      {link ? (
        <ExternalLink className={css.detailValue} href={formatDynamicLink(value)}>
          {value}
        </ExternalLink>
      ) : (
        <span className={css.detailValue}>{value}</span>
      )}
    </li>
  ) : null;
};

const renderInstagramField = (titleMsgId, link) => {
  return (
    <li className={css.detailsRow}>
      <span className={css.detailLabel}>
        <FormattedMessage id={titleMsgId} />
      </span>
      <ExternalLink
        className={classNames(css.detailValue, css.instagramDetailsValue)}
        href={formatDynamicLink(link)}
      >
        <IconSocialMediaInstagram className={css.instagramDetailsIcon} />
      </ExternalLink>
    </li>
  );
};

const SectionDetailsTalentMaybe = props => {
  const { publicData, listingConfig, description } = props;
  const { listingFields } = listingConfig || {};

  if (!publicData || !listingConfig) {
    return null;
  }

  const experienceOptions = findListingField(listingFields, 'experience');
  const salaryRequirementsOptions = findListingField(listingFields, 'salaryRequirements');
  const interestsOptions = findListingField(listingFields, 'interests');
  const styleOptions = findListingField(listingFields, 'style');
  const primarySpecialtyOptions = findListingField(listingFields, 'primarySpecialty');
  const secondarySpecialtyOptions = findListingField(listingFields, 'secondarySpecialty');
  const programOptions = findListingField(listingFields, 'program');
  const availabilityOptions = findListingField(listingFields, 'availability');
  const workplacePreferenceOptions = findListingField(listingFields, 'workplacePreference');
  const openToOptions = findListingField(listingFields, 'openTo');
  const graduationYearOptions = findListingField(listingFields, 'graduationYear');

  const industry = publicData?.industry;
  const experience = filterExtendedDataValues(experienceOptions, publicData?.experience);
  const primarySpecialty = findExtendedDataValue(
    primarySpecialtyOptions,
    publicData?.primarySpecialty
  );
  const secondarySpecialty = filterExtendedDataValues(
    secondarySpecialtyOptions,
    publicData?.secondarySpecialty
  );
  const hasSecondarySpecaility = secondarySpecialty?.length > 0;
  const salaryRequirements = filterExtendedDataValues(
    salaryRequirementsOptions,
    publicData?.salaryRequirements
  );
  const interests = filterExtendedDataValues(interestsOptions, publicData?.interests);
  const style = filterExtendedDataValues(styleOptions, publicData?.style);
  const program = filterExtendedDataValues(programOptions, publicData?.program);
  const availability = findExtendedDataValue(availabilityOptions, publicData?.availability);
  const workplacePreference = filterExtendedDataValues(
    workplacePreferenceOptions,
    publicData?.workplacePreference
  );

  const showPositionText = ' positions';
  const openTo = findExtendedDataValue(openToOptions, publicData?.openTo)+ showPositionText;
  const graduationYear = filterExtendedDataValues(
    graduationYearOptions,
    publicData?.graduationYear
  );
  
  const company = publicData?.company;
  const website = publicData?.website;
  const instagram = publicData?.instagram;
  const city = publicData?.address?.city;
  const title = publicData?.title;
  const university = publicData?.university;
  const degree = publicData?.degree;
  const customStyles = publicData?.customStyles;
  const hascustomStyles = customStyles?.length > 0;

  const isLink = true;
  const showLocation = false;

  return (
    <div className={css.sectionDetailsTalent}>
      {city ? <span className={css.sectionDetailsSubheading}>{city}</span> : null}
      {industry ? (
        <Heading className={css.sectionDetailsHeading} as="h2">
          {industry === ARCHITECTURE_KEY ? (
            <FormattedMessage id="ListingPage.architecture" />
          ) : (
            <FormattedMessage id="ListingPage.interiorDesign" />
          )}
        </Heading>
      ) : null}
      <ul className={css.details}>
        {showLocation ? renderField('ListingPage.location', city) : null}
        {renderField('ListingPage.company', company)}
        {renderField('ListingPage.title', title)}
        {renderField('ListingPage.university', university)}
        {renderField('ListingPage.degree', degree)}
        {renderField('ListingPage.website', website, isLink)}
        {renderInstagramField('ListingPage.instagram', instagram)}
        {renderField('ListingPage.description', description)}
        {renderField('ListingPage.experience', experience)}
        {renderField('ListingPage.specialty', primarySpecialty)}
        {renderField(
          'ListingPage.secondarySpecialty',
          hasSecondarySpecaility ? secondarySpecialty.join(', ') : null
        )}
        {renderField(
          'ListingPage.availability',
          availability?.length > 0 ? availability.join(', ') : null
        )}
        {renderField(
          'ListingPage.workplacePreference',
          workplacePreference?.length > 0 ? workplacePreference.join(', ') : null
        )}
        {openTo ?renderField('ListingPage.openTo', openTo):null}
        {renderField(
          'ListingPage.graduationYear',
          graduationYear?.length > 0 ? graduationYear.join(', ') : null
        )}
        {renderField(
          'ListingPage.salaryRequirements',
          salaryRequirements?.length > 0 ? salaryRequirements.join(', ') : null
        )}
        {renderField(
          'ListingPage.style',
          style?.length > 0
            ? hascustomStyles
              ? style.concat(customStyles).join(', ')
              : style.join(', ')
            : null
        )}
        {renderField('ListingPage.interests', interests?.length > 0 ? interests.join(', ') : null)}
        {renderField('ListingPage.program', program?.length > 0 ? program.join(', ') : null)}
      </ul>
    </div>
  );
};

export default SectionDetailsTalentMaybe;
