import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string, oneOf, object, number } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { intlShape, useIntl } from '../../util/reactIntl';
import { LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  createSlug,
} from '../../util/urlHelpers';
import { withViewport } from '../../util/uiHelpers';
import {
  ensureCompanySearchOrUserType,
  ensureListing,
  ensureOwnListing,
  ensureUser,
  removeLastInitial,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';

import {
  Page,
  NamedRedirect,
  LayoutSingleColumn,
  ResponsiveImage,
  Footer,
  Modal,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendConnectionEmail,
  sendInquiry,
  saveCompany,
  setInitialValues,
} from './ListingPage.duck';
import { appendRow } from '../../ducks/sheets';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleSubmitInquiry,
  handleContactUser,
  generateSheetsRow,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import InquiryForm from './InquiryForm/InquiryForm';
import SectionTitle from './SectionTitle';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionDetailsTalentMaybe from './SectionDetailsTalentMaybe';
import SectionConnect from './SectionConnect';
import SectionGallery from './SectionGallery';
import SectionDocuments from './SectionDocuments';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const MAX_MOBILE_SCREEN_WIDTH = 768;

const { UUID } = sdkTypes;

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );

  const {
    currentUser,
    getListing,
    getOwnListing,
    intl,
    params: rawParams,
    history,
    location,
    scrollingDisabled,
    showListingError,
    sendInquiryInProgress,
    sendInquiryError,
    inquirySubmitted,
    listingConfig: listingConfigProp,
    config,
    routeConfiguration,
    viewport,
    onSendInquiry,
    onAppendRow,
    onSendConnectionEmail,
    callSetInitialValues,
    onManageDisableScrolling,
    onSaveCompany,
    saveCompanyInProgress,
    saveCompanyError,
    saveCompanySubmitted,
    connectionLoading,
    isConnected,
  } = props;

  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  const listingConfig = listingConfigProp || config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer currentPage="ListingPage" />;
  const footer = <Footer className={css.footer} />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const userOrListingType = params.type || publicData?.type;
  const isCompanyUser = ensureCompanySearchOrUserType(userOrListingType);

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const listingTitle = isCompanyUser ? title : removeLastInitial(authorDisplayName);
  const richTitle = (
    <span>
      {richText(listingTitle, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const sheetsRow = generateSheetsRow(currentUser, currentAuthor);

  const commonParams = { params: rawParams, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    sheetsRow,
    onSendInquiry,
    onAppendRow,
    onSendConnectionEmail,
  });

  const { formattedPrice } = priceData(price, config.currency, intl);
  const images = currentListing.images;
  const hasImages = images?.length > 0;
  const firstImage = ensuredAuthor.profileImage
    ? ensuredAuthor.profileImage
    : hasImages
      ? images[0]
      : null;

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title: listingTitle, price: formattedPrice, marketplaceName }
  );

  const content = isCompanyUser ? (
    <div className={css.content}>
      <div className={css.contentFlex}>
        <div className={css.sectionDetailsWrapper}>
          <SectionDetailsMaybe
            listing={currentListing}
            metadata={metadata}
            listingConfig={listingConfig}
            description={description}
            intl={intl}
          />
          <SectionConnect
            className={css.sectionConnectMobile}
            title={richTitle}
            publicData={publicData}
            listingConfig={listingConfig}
            isOwnListing={isOwnListing}
            onContactUser={onContactUser}
            connectionLoading={connectionLoading}
            isConnected={isConnected}
          />
        </div>
        <div className={css.imageColumn}>
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['listing-card', 'listing-card-2x', 'listing-card-4x']}
          />
        </div>
      </div>
    </div>
  ) : (

    <div className={css.content}>
      <div className={css.contentFlexForTalent}>
        <div className={css.imageColumnForTalent}>
          <ResponsiveImage
            rootClassName={css.rootForImageForTalent}
            alt={title}
            image={firstImage}
            variants={['listing-card', 'listing-card-2x', 'listing-card-4x']}
          />
        </div>
        <div className={css.sectionDetailsTalentWrapper}>
          <SectionDetailsTalentMaybe
            publicData={publicData}
            description={description}
            listingConfig={listingConfig}
            intl={intl}
          />
          <SectionConnect
            className={css.sectionConnectTalentMobile}
            title={richTitle}
            publicData={publicData}
            listingConfig={listingConfig}
            isOwnListing={isOwnListing}
            onContactUser={onContactUser}
            connectionLoading={connectionLoading}
            isConnected={isConnected}
          />
        </div>
      </div>
    </div>
  );

  const currentUserSavedCompanies = currentUser?.attributes?.profile?.publicData?.savedCompanyIds;
  const isCurrentCompanySaved = !!currentUserSavedCompanies?.includes(listingId.uuid);
  const state = currentListing.attributes.state;
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={footer}>
        <div className={css.contentWrapper}>
          <SectionTitle title={richTitle} publicData={publicData} isCompanyUser={isCompanyUser} history={history}/>
          <div className={css.contentBox}>
            {currentListing.id ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
              />
            ) : null}
            {content}
            <SectionConnect
              className={isCompanyUser ? css.sectionConnect : css.sectionConnectTalent}
              title={richTitle}
              publicData={publicData}
              listingConfig={listingConfig}
              isOwnListing={isOwnListing}
              onContactUser={onContactUser}
              connectionLoading={connectionLoading}
              isConnected={isConnected}
              isPendingApproval={isPendingApproval}
            />
          </div>
          <SectionGallery
            listing={currentListing}
            variantPrefix={config.layout.listingImage.variantPrefix}
            isMobileLayout={isMobileLayout}
            publicData={publicData}
            onManageDisableScrolling={onManageDisableScrolling}
          />
          <SectionDocuments publicData={publicData} />
        </div>
        <Modal
          id="ListingPage.inquiry"
          contentClassName={css.inquiryModalContent}
          isOpen={inquiryModalOpen}
          onClose={() => setInquiryModalOpen(false)}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <InquiryForm
            className={css.inquiryForm}
            submitButtonWrapperClassName={css.inquirySubmitButtonWrapper}
            listingTitle={listingTitle}
            sendInquiryError={sendInquiryError}
            saveCompanyError={saveCompanyError}
            onSubmit={onSubmitInquiry}
            onSaveCompany={() => onSaveCompany(listingId.uuid)}
            authorDisplayName={authorDisplayName}
            inProgress={sendInquiryInProgress}
            saveCompanyInProgress={saveCompanyInProgress}
            inquirySubmitted={inquirySubmitted}
            saveCompanySubmitted={saveCompanySubmitted}
            isCurrentCompanySaved={isCurrentCompanySaved}
            isCompanyUser={isCompanyUser}
          />
        </Modal>
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  sendInquiryError: null,
  listingConfig: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSendConnectionEmail: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  listingConfig: object,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    sendInquiryInProgress,
    sendInquiryError,
    inquirySubmitted,
    inquiryModalOpenForListingId,
    saveCompanyInProgress,
    saveCompanyError,
    saveCompanySubmitted,
    connectionLoading,
    isConnected,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    sendInquiryInProgress,
    sendInquiryError,
    inquirySubmitted,
    saveCompanyInProgress,
    saveCompanyError,
    saveCompanySubmitted,
    connectionLoading,
    isConnected,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onSendInquiry: listing => dispatch(sendInquiry(listing)),
  onAppendRow: (id, row) => dispatch(appendRow(id, row)),
  onSendConnectionEmail: bodyParams => dispatch(sendConnectionEmail(bodyParams)),
  onSaveCompany: listingId => dispatch(saveCompany(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withViewport,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
