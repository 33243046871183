import { useEffect, useState } from 'react';
import { encodeLatLngBounds } from '../../util/urlHelpers';
import GeocoderMapbox, {
  locationBounds,
} from '../../components/LocationAutocompleteInput/GeocoderMapbox';
import { TALENT_LISTING_APPROVAL_DELAY } from './ProfileCompletePage.duck';
import { LISTING_TYPE_TALENT } from '../../util/types';
import * as configMaps from '../../config/configMaps';

const BOUNDS_DISTANCE = 30000;

/**
 * Custom hook to generate location search parameters using Mapbox geocoding.
 *
 * @param {Object} address - The address object containing city and search properties.
 * @returns {Object} An object containing searchParams and error.
 */
export const useLocationSearchParams = address => {
  const [searchParams, setSearchParams] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!window?.mapboxgl) {
          throw new Error('Mapboxgl is not available');
        }

        const { city, search: searchAddress } = address;
        const countryLimit = configMaps.search?.countryLimit;

        const geocoder = new GeocoderMapbox();
        const { predictions } = await geocoder.getPlacePredictions(city, countryLimit, null);
        const prediction = predictions?.[0];

        if (!prediction) {
          throw new Error('No predictions available');
        }

        const { origin } = await geocoder.getPlaceDetails(prediction);

        const profileAddress = prediction.place_name || city || searchAddress;
        const profileBounds = origin
          ? encodeLatLngBounds(locationBounds(origin, BOUNDS_DISTANCE))
          : null;

        const newSearchParams = profileBounds
          ? profileAddress
            ? `address=${profileAddress}&bounds=${profileBounds}`
            : `address=true&bounds=${profileBounds}`
          : null;

        setSearchParams(newSearchParams);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [address]);

  return { searchParams, error };
};

/**
 * Custom hook to track if profile approval is in progress and provide a countdown timer.
 * @param {string} profileType - Type of profile being approved.
 * @param {number} delay - Optional delay before setting approval in progress.
 * @returns {[boolean, number]} - Tuple containing whether profile approval is in progress and the remaining time in seconds until approval.
 */
export const useIsApproveProfileInProgress = (
  profileType,
  delay = TALENT_LISTING_APPROVAL_DELAY
) => {
  const isTalentType = profileType === LISTING_TYPE_TALENT;
  const [approveInProgress, setApproveInProgress] = useState(isTalentType);
  const [countdown, setCountdown] = useState(delay / 1000);

  useEffect(() => {
    if (isTalentType) {
      const timerId = setTimeout(() => {
        setApproveInProgress(false);
      }, delay);

      const intervalId = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown > 0) {
            return prevCountdown - 1;
          } else {
            clearInterval(intervalId);
            return 0;
          }
        });
      }, 1000);

      return () => {
        clearTimeout(timerId);
        clearInterval(intervalId);
      };
    }
  }, [isTalentType, delay]);

  return [approveInProgress, countdown];
};
