import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink } from '../../components';

import css from './ListingPage.module.css';

const SectionDescription = props => {
  const { publicData } = props;

  const documents = publicData?.documents;
  const hasDocuments = documents?.length > 0;

  return hasDocuments ? (
    <div className={css.sectionDocuments}>
      <h2 className={css.documentsTitle}>
        <FormattedMessage id="SectionDocuments.title" />
      </h2>
      <div className={css.documentsContent}>
        <ul className={css.documents}>
          {documents.map(document => {
            return document?.id ? (
              <li key={document.id} className={css.document}>
                <ExternalLink href={document.link}>{document.name}</ExternalLink>
              </li>
            ) : null;
          })}
        </ul>
      </div>
    </div>
  ) : null;
};

export default SectionDescription;
