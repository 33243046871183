import React from 'react';
import { IconBack, IconCouch, IconHome } from '../../components';

import css from './ListingPage.module.css';

const industryIcon = industry => {
  if (industry === 'architecture') {
    return <IconHome className={css.titleIndustryIcon} />;
  } else if (industry === 'interior-design') {
    return <IconCouch className={css.titleIndustryIcon} />;
  }

  return null;
};

const SectionTitle = props => {
  const { title, publicData, isCompanyUser,history } = props;

  const industry = publicData?.industry;
  return (
    <div className={css.sectionTitleContent}>
      <div className={css.iconBack} onClick={() => history.goBack()}><IconBack /> </div>
      <div className={css.sectionTitle}>
        <h1 className={css.title}>{title}</h1>
        {isCompanyUser ? industryIcon(industry) : null}
      </div>
    </div>

  );
};

export default SectionTitle;
