import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_FIELD_OPEN_TO_BOTH_KEY, LISTING_TYPE_COMPANY } from '../../util/types';
import { formatDynamicLink, getAddressCity, getMainAddress } from '../../util/data';
import { Heading, NamedLink, ExternalLink } from '../../components';
import { ExpandableBio } from '../../components/UserCard/UserCard';

import { useLocationSearchParams } from '../ProfileCompletePage/ProfileCompletePage.helpers';
import css from './ListingPage.module.css';
import { isEmpty } from 'lodash';

const findListingField = (fields, key) => fields.find(field => field.key === key)?.enumOptions;
const findExtendedDataValue = (options, valueKey) =>
  options.find(option => option.option === valueKey)?.label;
const filterExtendedDataValues = (options, valueKeys) =>
  options.filter(o => valueKeys?.includes(o.option)).map(o => o.label);

const renderOpenTo = (options, value) => {
  const isBoth = value.toLowerCase() === LISTING_FIELD_OPEN_TO_BOTH_KEY;

  return isBoth
    ? options
      .filter(o => o.option !== LISTING_FIELD_OPEN_TO_BOTH_KEY)
      .map(o => o.label + ' candidates')
      .join(' and ')
    : value + ' candidates';
};

const SectionDetailsMaybe = props => {
  const { listing, listingConfig, description } = props;
  const { publicData } = listing.attributes;

  if (!publicData || !listingConfig) {
    return null;
  }

  const { listingFields } = listingConfig;
  const industryOptions = findListingField(listingFields, 'industry');
  const primarySpecialtyOptions = findListingField(listingFields, 'primarySpecialty');
  const secondarySpecialtyOptions = findListingField(listingFields, 'secondarySpecialty');
  const companySizeOptions = findListingField(listingFields, 'companySize');
  const openToOptions = findListingField(listingFields, 'openTo');
  const styleOptions = findListingField(listingFields, 'style');

  const industry = findExtendedDataValue(industryOptions, publicData.industry);
  const primarySpecialty = findExtendedDataValue(
    primarySpecialtyOptions,
    publicData.primarySpecialty
  );
  const secondarySpecialty = filterExtendedDataValues(
    secondarySpecialtyOptions,
    publicData.secondarySpecialty
  );
  const hasSecondarySpecaility = secondarySpecialty?.length > 0;
  const companySize = findExtendedDataValue(companySizeOptions, publicData.companySize);
  const openTo = findExtendedDataValue(openToOptions, publicData?.openTo);
  const style = filterExtendedDataValues(styleOptions, publicData.style);
  const website = publicData.website;
  const instagram = publicData.instagram;

  const addressFromPublicData = publicData?.address;
  const googleMapsUrl = publicData?.googleMapsUrl
  const address = getMainAddress(addressFromPublicData, googleMapsUrl);
  const city = getAddressCity(addressFromPublicData);

  const secondaryAddress = getMainAddress(publicData?.secondaryAddress);

  const { searchParams: searchParamsForAddressField } = useLocationSearchParams(
    addressFromPublicData
  );
  const toSearchParamsForAddressFieldMaybe = searchParamsForAddressField
    ? { to: { search: searchParamsForAddressField } }
    : {};

  return (
    <div className={css.sectionDetails}>
      {city && searchParamsForAddressField ? (
        <NamedLink
          className={css.sectionDetailsSubheading}
          name="SearchPage"
          params={{
            type: LISTING_TYPE_COMPANY,
          }}
          {...toSearchParamsForAddressFieldMaybe}
        >
          {city}
        </NamedLink>
      ) : (
        <span className={css.sectionDetailsSubheading}>{city}</span>
      )}
      {industry && (
        <Heading className={css.sectionDetailsHeading} as="h2">
          {industry}
        </Heading>
      )}
      <ul className={css.details}>
        {address && (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.address" />
            </span>
            <span className={css.detailValue}>
              <div>{address}</div>
              {secondaryAddress && !isEmpty(secondaryAddress)? <div>{secondaryAddress}</div> : null}
            </span>
          </li>
        )}
        {industry && (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.industry" />
            </span>
            <span className={css.detailValue}>{industry}</span>
          </li>
        )}
        {primarySpecialty && (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.specialty" />
            </span>
            <span className={css.detailValue}>{primarySpecialty}</span>
          </li>
        )}
        {hasSecondarySpecaility && (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.secondarySpecialty" />
            </span>
            <span className={css.detailValue}>{secondarySpecialty.join(', ')}</span>
          </li>
        )}
        {companySize && (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.companySize" />
            </span>
            <span className={css.detailValue}>{companySize}</span>
          </li>
        )}
        {openTo && (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.openTo" />
            </span>
            <span className={css.detailValue}>{renderOpenTo(openToOptions, openTo)}</span>
          </li>
        )}
        {website && (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.website" />
            </span>
            <ExternalLink className={css.detailValue} href={formatDynamicLink(website)}>
              {website}
            </ExternalLink>
          </li>
        )}
        {instagram && (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.instagram" />
            </span>
            <ExternalLink className={css.detailValue} href={formatDynamicLink(instagram)}>
              {instagram}
            </ExternalLink>
          </li>
        )}
        {style?.length > 0 ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.style" />
            </span>
            <span className={css.detailValue}>{style.join(', ')}</span>
          </li>
        ) : null}

        {description ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.description" />
            </span>
            <ExpandableBio className={css.detailValue} bio={description} />
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default SectionDetailsMaybe;
